<template>
<b-card>

    <validation-observer ref="simpleRules">
        <b-form>
            <!-- Section Informations -->
            <h4>{{$t('Informations')}}</h4>
            <hr style="margin-bottom: 16px;">
            <b-row>
                <!-- Field:  Name -->
                <b-col cols="12" md="4">
                    <b-form-group :label="$t('Name')" label-for="name">
                        <validation-provider #default="{ errors }" name="name" rules="required">
                            <b-form-input id="name" v-model="groupData.name" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>
                <!-- Field:  Name -->
                <b-col cols="12" md="8">
                    <b-form-group :label="$t('Descripition')" label-for="Descripition">
                        <validation-provider #default="{ errors }" name="Descripition" rules="">
                            <b-form-input id="name" v-model="groupData.description" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

            </b-row>
            <b-row>
                <b-col cols="12" md="12">
                    <b-button class="float-right" variant="primary" type="submit" @click.prevent="validationForm">
                        {{$t('Save')}}
                    </b-button>
                </b-col>
            </b-row>
            <hr v-show="groupData.id"/>

            <b-row  v-if="groupData.id" class="mt-3 mb-2">
                <b-col cols="12" md="12">
                    <div class="d-flex align-items-center justify-content-start">
                        <h4 class="d-inline-block mr-1" >{{ $t("Assets") }}</h4>
                        <!-- <b-avatar class="mr-2 mb-0" @click="openModalBind" button rounded="sm" variant="primary" size="24px" v-b-tooltip.hover title="Adicionar">
                            <feather-icon icon="PlusCircleIcon" />
                        </b-avatar> -->
                        <b-button class="mr-2 mb-0" @click="openModalBind" variant="primary" size="sm">{{$t('Add Asset')}}</b-button>
                    </div>
                </b-col>
            </b-row>



            <template  v-if="groupData.id && totalAssets > 0">
                    <div >
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">{{$t('Identifier')}}</th>
                                        <th scope="col">{{$t('Nickname')}}</th>
                                        <th scope="col">{{$t('Manufacturer')}}</th>
                                        <th scope="col">{{$t('Model')}}</th>
                                        <th scope="col">{{$t('Actions')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="asset in assets">
                                        <td>{{ asset.plate || asset.chassisNumber }}</td>
                                        <td>{{ asset.nickname }}</td>
                                        <td>{{ asset.manufacturerName }}</td>
                                        <td>{{ asset.modelName }}</td>
                                        <td>
                                            <div >
                                                <b-avatar @click="modalDelete(asset.assetId, asset.bindId)"  button rounded="sm" variant="danger" size="24px" v-b-tooltip.hover title="Remover">
                                                    <feather-icon icon="Trash2Icon" />
                                                </b-avatar>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="py-1 " style="background-color: #f3f2f7;">
                            <b-row>
                                <!-- Pagination -->
                                <b-col cols="12" sm="12" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                                    <b-pagination v-model="currentPage" :total-rows="totalAssets" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                                        <template #prev-text>
                                            <feather-icon icon="ChevronLeftIcon" size="18" />
                                        </template>
                                        <template #next-text>
                                            <feather-icon icon="ChevronRightIcon" size="18" />
                                        </template>
                                    </b-pagination>
                                </b-col>
                            </b-row>
                        </div>
                    </div>


            </template>



        </b-form>
    </validation-observer>
    <b-modal v-model="showModal" no-close-on-backdrop id="modal-cadastro" size="md" title="Cadastro" title-class="font-18" hide-footer @hide="limparFormCadastro">
    <div class="row ">
        <div class="col-md-12">
            <p class="sub-header font-weight-bold">{{$t('Choose the asset to add to the group')}}</p>
        </div>
    </div>
    <form >
        <div class="row">
            <b-col cols="12" md="12">
                <b-form-group :label="$t('Asset')" label-for="asset">
                    <vue-autosuggest v-model="asset.name" :suggestions="filteredOptions" :input-props="inputProps" @selected="selectHandler" @input="onInputChange">
                        <template slot-scope="{suggestion}">
                            <span class="my-suggestion-item">{{ suggestion.item.plate ?  suggestion.item.plate : suggestion.item.chassisNumber}}</span>
                        </template>
                    </vue-autosuggest>
                </b-form-group>
            </b-col>
        </div>
        <div class="form-group text-right mt-1">
            <button class="btn btn-primary" type="button" @click="bindGroup()">{{$t('Add')}}</button>
        </div>

    </form>
</b-modal>
</b-card>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
} from 'bootstrap-vue'
import {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BFormTextarea,
    BNavItemDropdown,
    BDropdownItem,
    BImg,
    BAvatar,
    BPagination
} from 'bootstrap-vue'
import {
    ref,
    onUnmounted
} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import managementStoreModule from '../managementStoreModule'

import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.br'

import vSelect from 'vue-select'
import {
    VueAutosuggest
} from 'vue-autosuggest'

import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import {
    required,
    email,
    confirmed,
    url,
    between,
    alpha,
    integer,
    password,
    min,
    max,
    digits,
    alphaDash,
    length,
} from '@validations'

export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,

        ValidationProvider,
        ValidationObserver,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BCardText,
        BFormTextarea,
        BNavItemDropdown,
        BDropdownItem,
        BImg,
        BAvatar,
        BPagination,

        Cleave,
        vSelect,
        VueAutosuggest,
        flatPickr

    },
    data() {
        return {
            myId: router.currentRoute.params.id,
            required,
            email,
            confirmed,
            url,
            between,
            alpha,
            integer,
            password,
            min,
            digits,
            alphaDash,
            length,
            max,


            datasuggest: [],
            filteredOptions: [],
            inputProps: {
                id: 'autosuggest__input',
                class: 'form-control',
                placeholder: "Search for your asset ...",
            },
            limit: 5,
            selected: null,

            groupData: {
                id: '',
                name: '',
                description:''
            },

            assets:[],

            totalAssets: 0,
            currentPage: 1,
            perPage: 10,

            asset:{
                name:'',
                id:''
            },
            showModal: false


        }
    },
    created() {
       if(router.currentRoute.params.id != undefined) {
            this.fetchGroup()
            this.fetchAssetsOfAssetGroup()
       }
    },
    computed: {

    },
    methods: {

        selectHandler(option) {
            //this.selected = option.item
            //this.searchQuery = option.item.plate ? option.item.plate : option.item.chassisNumber
            console.log('opt: ', option)
            this.asset.id = option.item.id
            this.asset.name = option.item.plate ? option.item.plate : option.item.chassisNumber
            this.filteredOptions = []


        },

        onInputChange(text) {
            if (text === '' || text === undefined || text.length < 3) {

                return
            }

            store.dispatch('app-management/fetchAssets', {
                    q: text,
                    excludeGrouped: true
                })
                .then(response => {

                    this.filteredOptions = [{
                        data: response.data.data,
                    }]

                })
                .catch(error => {

                    console.log(error)

                })
        },

        openModalBind(id){
            console.log('abriu')
            this.showModal = true
            //this.groupId = id
        },

        limparFormCadastro(){
            this.asset = {
                name:'',
                id:''
            }

            //this.groupId = ''
        },

        bindGroup(){
            this.showModal = false

            store.dispatch(`${this.MANAGEMENT_APP_STORE_MODULE_NAME}/bindAssetToAssetGroup`, {
                    assetId: this.asset.id,
                    assetGroupId: router.currentRoute.params.id
                })
                .then(response => {
                    this.$swal('Success', this.$t('Asset added'), 'success')
                    this.fetchAssetsOfAssetGroup()
                })
                .catch(error => {
                    this.$swal('Error', '', 'error')

                })
        },

        modalDelete(assetId, bindId) {
            this.$swal({
                title: this.$t('Are you sure?'),
                text: this.$t("You can't revert your action"),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('No'),
                showCloseButton: true,
                showLoaderOnConfirm: true,
                customClass: {
                    cancelButton: 'cancelBtn',
                    confirmButton: 'mr-1',
                }
            }).then((result) => {
                if (result.value) {

                    this.unBindAssetToAssetGroup(assetId, bindId)

                } else {}
            })
        },

        unBindAssetToAssetGroup(assetId, bindId) {
            store.dispatch('app-management/unBindAssetToAssetGroup', {
                    assetId: assetId,
                    bindId: bindId,
                    assetGroupId: this.myId

                })
                .then(response => {
                    //this.$swal(this.$t("Deleted"), `${this.$t("You successfully deleted")}`, 'success')
                    this.fetchAssetsOfAssetGroup()

                })
                .catch(error => {
                    this.$swal('Error', 'Your fuelling is still intact', 'error')
                    console.log(error);
                })
        },


        fetchAssetsOfAssetGroup() {

            store
                .dispatch('app-management/fetchAssetsOfAssetGroup', {
                    groupId: router.currentRoute.params.id,
                    perPage: this.perPage,
                    page: this.currentPage,
                })
                .then(response => {
                    this.assets = response.data.data
                    this.totalAssets = response.data.totalItems
                })
                .catch(() => {

                })

        },


        fetchGroup(){
            store.dispatch('app-management/fetchGroup', {

                    id: router.currentRoute.params.id
                })
                .then(response => {
                    this.groupData = response.data
                })
                .catch(e => {
                    console.log(e)
                })
        },

        removeAsset(index){
            // if (index >= 0 && index < this.groupData.assets.length) {
            //     this.groupData.assets.splice(index, 1);
            // }
        },

        addAssets(){
            var newAsset = {
                name:'',
                assetId:'',
            }
            this.groupData.assets.push(newAsset)
        },

        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {


                    if (this.groupData.id === "") {
                        store.dispatch('app-management/addGroup', {

                                groupData: this.groupData
                            })
                            .then(response => {
                                //alert('Error 404')
                                this.msgDialog('Successful ', 'success')

                                router.push({
                                    name: 'list-group'
                                })

                            })
                            .catch(error => {
                                this.msgDialog('FAILURE ', 'error')
                                // TODO: FAZER MSG ESPESSIFICA PARA CADAS ERRO DA API
                            })

                    } else {

                        store.dispatch('app-management/updateassetgroup', this.groupData)
                            .then(response => {
                                //alert('Error 404')
                                this.msgDialog('Successful ', 'success')

                                router.push({
                                    name: 'list-group'
                                })

                            })
                            .catch(error => {
                                this.msgDialog('FAILURE ', 'error')
                                // TODO: FAZER MSG ESPESSIFICA PARA CADAS ERRO DA API
                            })
                    }
                }
            })
        },

        msgDialog(msg, icon) {
            this.$swal({
                position: 'top-end',
                icon: icon,
                title: msg,
                showConfirmButton: false,
                timer: 1500 * 3,
                customClass: {
                    confirmButton: 'btn btn-primary',
                },

                buttonsStyling: false,
            })
        },



        suggestionSelected() {
            //console.log('Selecionado', this.selected)
        }
    },
    setup() {


        const MANAGEMENT_APP_STORE_MODULE_NAME = 'app-management'

        // Register module
        if (!store.hasModule(MANAGEMENT_APP_STORE_MODULE_NAME)) store.registerModule(MANAGEMENT_APP_STORE_MODULE_NAME, managementStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(MANAGEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(MANAGEMENT_APP_STORE_MODULE_NAME)
        })





        return {
            MANAGEMENT_APP_STORE_MODULE_NAME

        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style><style>
.title-head {
    margin-bottom: 32px;
}
</style>
